<template>
  <div>
    <HStack
      v-if="isInvoiceRequest"
      class="my-25 gap-2"
    >
      <BButton
        size="sm"
        :variant="!createdTypeStatusFilter ? 'info' : 'outline-info'"
        @click="() => $emit('update:createdTypeStatusFilter', null)"
      >
        {{ $t('invoice.requestInvoice.createdTypeStatus.ALL') }}
      </BButton>
      <BButton
        v-for="createdTypeStatus in CREATED_TYPE_STATUS_OPTIONS"
        :key="createdTypeStatus.value"
        size="sm"
        :variant="createdTypeStatus.value === createdTypeStatusFilter ? 'info' : 'outline-info'"
        @click="() => $emit('update:createdTypeStatusFilter', createdTypeStatus.value)"
      >
        {{ $t(`invoice.requestInvoice.createdTypeStatus.${createdTypeStatus.value}`) }}
      </BButton>
    </HStack>
    <AppCollapse class="mb-50">
      <AppCollapseItem
        title
        :is-visible="true"
        class-body="p-0"
        class-header="p-0"
      >
        <template #header>
          <h4 class="card-title font-weight-bolder py-50">
            {{ $t('filters') }}
          </h4>
        </template>

        <div
          id="invoice-list-filter"
          class="p-0"
        >
          <b-row
            align-v="center"
            align-h="start"
          >
            <!-- ANCHOR START DATE -->
            <b-col
              cols="12"
              :md="isInvoiceRequest ? 3 : 2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.filters.from')"
              >
                <flat-pickr
                  :value="startDate"
                  class="form-control"
                  :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                  :config="{
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    altInput: true,
                    maxDate: endDate ? endDate : today,
                    locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                    disableMobile: true,
                  }"
                  @input="val => $emit('update:startDate', val)"
                />
              </b-form-group>
            </b-col>

            <!-- ANCHOR END DATE -->
            <b-col
              cols="12"
              :md="isInvoiceRequest ? 3 : 2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.filters.to')"
              >
                <flat-pickr
                  :value="endDate"
                  class="form-control"
                  :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                  :config="{
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    altInput: true,
                    minDate: startDate,
                    maxDate: today,
                    locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                    disableMobile: true,
                  }"
                  @input="val => $emit('update:endDate', val)"
                />
              </b-form-group>
            </b-col>

            <!-- ANCHOR AGENCY CODE -->
            <b-col
              cols="12"
              :md="isInvoiceRequest ? 3 : 2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.filters.agencyCode')"
              >
                <vue-autosuggest
                  id="agency-code"
                  :value="agencyCode"
                  :suggestions="agencySuggestions"
                  :input-props="{
                    placeholder: `${$t('invoice.ph.agencyCode')}`,
                    class: 'form-control'
                  }"
                  :get-suggestion-value="getSuggestionValue"
                  @input="inputAgencyFilterHandle"
                  @selected="val => $emit('update:agencyCode', val?.item?.agencyCode?.toUpperCase())"
                >
                  <template #default="{ suggestion }">
                    <span class="d-block font-weight-bold text-truncate text-uppercase">
                      <span class="text-info text-uppercase">{{ suggestion.item.agencyCode }}</span> <small>({{ suggestion.item.agencyName }})</small>
                    </span>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </b-col>

            <!-- ANCHOR trạng thái hoá đơn -->
            <b-col
              v-if="!isInvoiceRequest"
              cols="12"
              md="2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.invoiceStatus.title')"
              >
                <v-select
                  :value="invoiceStatus"
                  class="w-100 vue-select-class"
                  style="font-size: 1rem;"
                  :options="INVOICE_STATUS.filter(s => s.value !== INVOICE_STATUS_VALUES.SIGNED)"
                  label="label"
                  clearable
                  :placeholder="$t('placeholderSelect')"
                  :reduce="val => val.value"
                  @input="val => $emit('update:invoiceStatus', val)"
                >
                  <template #option="data">
                    <BBadge
                      :variant="resolveInvoiceStatus(data.label)"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.invoiceStatus.${data.label}`) }}
                    </BBadge>
                  </template>

                  <template #selected-option="data">
                    <BBadge
                      :variant="resolveInvoiceStatus(data.label)"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.invoiceStatus.${data.label}`) }}
                    </BBadge>
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!-- ANCHOR trạng thái phát hành -->
            <b-col
              v-if="!isInvoiceRequest"
              cols="12"
              md="2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.releaseStatus.title')"
              >
                <v-select
                  :value="releaseStatus"
                  class="w-100 vue-select-class"
                  style="font-size: 1rem;"
                  :options="RELEASE_STATUS"
                  label="label"
                  clearable
                  :placeholder="$t('placeholderSelect')"
                  :reduce="val => val.value"
                  @input="val => $emit('update:releaseStatus', val)"
                >
                  <template #option="data">
                    <BBadge
                      :variant="resolveReleaseStatus(data.label)"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.releaseStatus.${data.label}`) }}
                    </BBadge>
                  </template>

                  <template #selected-option="data">
                    <BBadge
                      :variant="resolveReleaseStatus(data.label)"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.releaseStatus.${data.label}`) }}
                    </BBadge>
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!-- ANCHOR trạng thái gửi email -->
            <!-- <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.sendMailStatus.title')"
              >
                <v-select
                  :value="sendMailStatus"
                  class="w-100 vue-select-class"
                  style="font-size: 1rem;"
                  :options="SEND_MAIL_STATUS"
                  label="label"
                  clearable
                  :placeholder="$t('placeholderSelect')"
                  :reduce="val => val.value"
                  @input="val => $emit('update:sendMailStatus', val)"
                >
                  <template #option="data">
                    <BBadge
                      :variant="`light-${resolveSendMailStatus(data.label).color}`"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.sendMailStatus.${data.label}`) }}
                    </BBadge>
                  </template>

                  <template #selected-option="data">
                    <BBadge
                      :variant="`light-${resolveSendMailStatus(data.label).color}`"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.sendMailStatus.${data.label}`) }}
                    </BBadge>
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col> -->

            <!-- ANCHOR trạng thái ký -->
            <b-col
              v-if="!isInvoiceRequest"
              cols="12"
              md="2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.signStatus.title')"
              >
                <v-select
                  :value="signStatus"
                  class="w-100 vue-select-class"
                  style="font-size: 1rem;"
                  :options="SIGN_STATUS"
                  label="label"
                  clearable
                  :placeholder="$t('placeholderSelect')"
                  :reduce="val => val.value"
                  @input="val => $emit('update:signStatus', val)"
                >
                  <template #option="data">
                    <BBadge
                      :variant="`light-${resolveSignStatus(data.label).color}`"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.signStatus.${data.label}`) }}
                    </BBadge>
                  </template>

                  <template #selected-option="data">
                    <BBadge
                      :variant="`light-${resolveSignStatus(data.label).color}`"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.signStatus.${data.label}`) }}
                    </BBadge>
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!-- ANCHOR Tên khách hàng -->
            <b-col
              cols="12"
              :md="isInvoiceRequest ? 3 : 2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.buyer.name')"
              >
                <b-form-input
                  :value="buyerName"
                  :placeholder="$t('invoice.ph.buyerName')"
                  :formatter="trimInput"
                  lazy-formatter
                  @input="val => $emit('update:buyerName', val)"
                  @keyup.enter="refetchData"
                />
              </b-form-group>
            </b-col>

            <!-- ANCHOR Số hoá đơn -->
            <b-col
              v-if="!isInvoiceRequest"
              cols="12"
              md="2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.columns.invNumber')"
              >
                <b-form-input
                  :value="invNumber"
                  :placeholder="$t('invoice.ph.invNumber')"
                  :formatter="onlyUpperCaseFormatter"
                  @input="val => $emit('update:invNumber', val)"
                  @keyup.enter="refetchData"
                />
              </b-form-group>
            </b-col>

            <!-- ANCHOR Mã tra cứu -->
            <b-col
              cols="12"
              :md="isInvoiceRequest ? 3 : 2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.filters.referenceCode')"
              >
                <b-form-input
                  :value="referenceCode"
                  :placeholder="$t('invoice.ph.referenceCode')"
                  :formatter="onlyUpperCaseFormatter"
                  @input="val => $emit('update:referenceCode', val)"
                  @keyup.enter="refetchData"
                />
              </b-form-group>
            </b-col>

            <!-- ANCHOR Nhãn -->
            <b-col
              cols="12"
              :md="isInvoiceRequest ? 3 : 2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.filters.tags')"
              >
                <v-select
                  :value="tagsFilter"
                  class="w-100"
                  style="font-size: 1rem"
                  :options="tagOptions"
                  multiple
                  taggable
                  push-tags
                  label="label"
                  clearable
                  :placeholder="$t('invoice.create.info.phAssignTag')"
                  :reduce="(val) => val"
                  :loading="loadingTags"
                  @open="onOpenTagHandle"
                  @search="onSearchTagHandle"
                  @input="onInputTagHandle"
                >
                  <template #option="data">
                    {{ data.label }}
                  </template>

                  <template #selected-option="data">
                    {{ data.label }}
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                  <template #spinner="{ loading }">
                    <div
                      v-if="loading"
                      style="border-left-color: rgba(88, 151, 251, 0.71)"
                      class="vs__spinner"
                    />
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!-- ANCHOR trạng thái phí xuất chênh  -->
            <b-col
              v-if="onlyTotalAmountAdditional"
              cols="12"
              md="2"
            >
              <b-form-group
                class="mb-0"
                label-class="h5 py-0"
                :label="$t('invoice.invoiceAdditionalStatus.title')"
              >
                <v-select
                  :value="additionalAmountStatus"
                  class="w-100 vue-select-class"
                  style="font-size: 1rem;"
                  :options="ADDITIONAL_AMOUNT_STATUS"
                  label="label"
                  clearable
                  :placeholder="$t('placeholderSelect')"
                  :reduce="val => val.value"
                  @input="val => $emit('update:additionalAmountStatus', val)"
                >
                  <template #option="data">
                    <BBadge
                      :variant="resolveInvoiceAdditionalStatus(data.label)"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.invoiceAdditionalStatus.${data.label}`) }}
                    </BBadge>
                  </template>

                  <template #selected-option="data">
                    <BBadge
                      :variant="resolveInvoiceAdditionalStatus(data.label)"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.invoiceAdditionalStatus.${data.label}`) }}
                    </BBadge>
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="auto"
              class="flex-fill"
              align-self="end"
            >
              <HStack end>
                <!-- ANCHOR Button Search -->
                <b-button
                  variant="primary"
                  size="sm"
                  class="p-50"
                  @click="refetchData"
                >
                  <span class="text-nowrap d-flex-center gap-1">
                    <IAmIcon
                      icon="search"
                      size="16"
                    />
                    {{ $t('search') }}
                  </span>
                </b-button>

                <!-- ANCHOR Button Clear Search Filters -->
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="p-50"
                  @click="onClearFilters"
                >
                  <span class="text-nowrap d-flex-center gap-1">
                    <IAmIcon
                      icon="delete"
                      size="16"
                    />
                    {{ $t('invoice.create.selectTicket.clearFilter') }}
                  </span>
                </b-button>
              </HStack>
            </b-col>
          </b-row>
        </div>
      </AppCollapseItem>
    </AppCollapse>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { VueAutosuggest } from 'vue-autosuggest'
import { debounce, cloneDeep } from 'lodash-es'

import { apiAgencies } from '@/api'
import {
  RELEASE_STATUS, INVOICE_STATUS, SEND_MAIL_STATUS, SIGN_STATUS,
  INVOICE_STATUS_VALUES,
  ADDITIONAL_AMOUNT_STATUS,
  CREATED_TYPE_STATUS_OPTIONS,
} from '@/constants/invoice'
import useInvoiceHandle, {
  resolveInvoiceStatus, resolveReleaseStatus, resolveSendMailStatus, resolveSignStatus, resolveInvoiceAdditionalStatus,
} from '@/views/invoices/useInvoiceHandle'

import { onlyUpperCaseFormatter, trimInput } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    vSelect,
    flatPickr,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    VueAutosuggest,
  },
  props: {
    startDate: {
      type: String, default: '',
    },
    endDate: {
      type: String, default: '',
    },
    invoiceStatus: {
      type: String, default: '',
    },
    releaseStatus: {
      type: String, default: '',
    },
    sendMailStatus: {
      type: String, default: '',
    },
    signStatus: {
      type: String, default: '',
    },
    additionalAmountStatus: {
      type: String, default: '',
    },
    onlyTotalAmountAdditional: {
      type: Boolean, default: false,
    },
    agencyCode: {
      type: String, default: '',
    },
    buyerName: {
      type: String, default: '',
    },
    referenceCode: {
      type: String, default: '',
    },
    invNumber: {
      type: String, default: '',
    },
    tagsFilter: {
      type: Array, default: () => [],
    },
    isInvoiceRequest: {
      type: Boolean, default: false,
    },
    createdTypeStatusFilter: {
      type: String, default: '',
    },
  },
  setup(props, { emit }) {
    const { loadingTags, getTags } = useInvoiceHandle()

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const agencySuggestions = ref([
      {
        data: [],
      },
    ])
    const inputAgencyFilterHandle = debounce(input => {
      emit('update:agencyCode', input)
      if (input === '' || input === undefined) {
        return
      }
      apiAgencies
        .fetchAgencies({ searchText: input })
        .then(response => {
          agencySuggestions.value = [
            {
              data: response.data.items,
            },
          ]
        })
    }, 200)

    function getSuggestionValue(suggestion) {
      return suggestion?.item?.agencyCode
    }

    const refetchData = () => {
      emit('refetch-data')
    }

    const onClearFilters = () => {
      emit('refetch-data-without-filters')
    }

    const tagOptions = ref([])
    const tagOptionsDefault = ref([])
    async function onOpenTagHandle() {
      if (tagOptionsDefault.value.length) {
        tagOptions.value = cloneDeep(tagOptionsDefault.value)
        return
      }
      const res = await getTags()
      tagOptions.value = cloneDeep(res.tags)
      tagOptionsDefault.value = cloneDeep(res.tags)
    }

    function onInputTagHandle(val) {
      emit('update:tagsFilter', val)
    }

    const onSearchTagHandle = debounce(async val => {
      const res = await getTags(val)
      tagOptions.value = cloneDeep(res.tags)
      tagOptionsDefault.value = cloneDeep(res.tags)
    }, 300)

    return {
      Vietnamese,
      today,
      onClearFilters,
      onlyUpperCaseFormatter,
      trimInput,
      inputAgencyFilterHandle,
      agencySuggestions,
      getSuggestionValue,
      refetchData,
      RELEASE_STATUS,
      INVOICE_STATUS,
      SEND_MAIL_STATUS,
      SIGN_STATUS,
      ADDITIONAL_AMOUNT_STATUS,
      resolveInvoiceStatus,
      resolveReleaseStatus,
      resolveInvoiceAdditionalStatus,
      resolveSendMailStatus,
      resolveSignStatus,
      tagOptions,
      onOpenTagHandle,
      loadingTags,
      onInputTagHandle,
      onSearchTagHandle,
      INVOICE_STATUS_VALUES,
      CREATED_TYPE_STATUS_OPTIONS,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
.vue-select-class {
  ::v-deep .vs__dropdown-option--selected {
    color: black !important;
    background-color: #DCDCDC70 !important;
    &.vs__dropdown-option--highlight {
      color: black !important;
      background-color: #DCDCDC70 !important;
    }
  }
}

</style>
